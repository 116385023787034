import React, { useEffect, useState, useContext } from 'react';
import { axios } from '../../config';
import M from 'materialize-css';
import ListaProductos from './listaProductos';
import { Link } from 'react-router-dom';
import { getDate } from '../../Utils'
import ModalContactNow from '../../components/Modals/ModalContactNow'
import { UserContext } from '../../UserContext';

const Ventas = () => {

	const { user } = useContext(UserContext)
	const [pedidos, setPedidos] = useState([]) 
	const [list, setList] = useState([])
	const [cupon, setCupon] = useState({})
	const [search, setSearch] = useState('')

	useEffect(()=>{
		axios.get('/api/ventasonline').then((res)=>{
			setPedidos(res.data)
			console.log(res.data)
		}).catch(e=>console.log("error",e))
	},[])

	const _enviar = (pedido_id, envio_id) =>{
		axios.post('/api/pedido_empaquetado',{pedido_id, envio_id}).then((r)=>{
			setPedidos(pedidos.map(i=> parseInt(i.id) === r.data.id?r.data:i))
			M.toast({html:'Actualizado! Pedido enviado!'})
		}).catch(e=>console.log('error',e))
	}

	return (
			<>
		<ModalContactNow />
		<div className="col s12" style={{marginTop:50, marging:0, padding:0, width:'78vw'}}>
			{/*MODAL VER PEDIDO COMPLETO*/}
			<ListaProductos  list={list} cupon={cupon} />
			<nav style={{margin:50}}>
				<div className="nav-wrapper white">
					<form>
						<div className="input-field">
							<input value={search} onChange={(e)=>setSearch(e.target.value)} id="search" type="search" placeholder='Search Order ID' required style={{marginLeft:50}}/>
							<label className="label-icon" htmlFor="search"><i className="material-icons black-text">search</i></label>
							<i className="material-icons">close</i>
						</div>
					</form>
				</div>
			</nav>

			{pedidos.length===0&&
			<div className='card-panel'>
				<center>
					<h4>Sin Pedidos</h4>
				</center>
			</div>
			}

			{/*TABLE*/}
			<table className="striped">
				<thead>
					<tr>
						<th>#</th>
						<th>Date</th>
						<th>OrderId</th>
						<th>Status</th>
						<th>Customer</th>
						<th>Shipper</th>
						<th>Seller</th>
						<th>Info</th>
					</tr>
				</thead>
				<tbody>
				{pedidos.filter(p=>p.id.toString().includes(search)).map((p,i)=>
					p.source_object_reference.envio?.map((envio,ii)=>
					<tr key={ii}>
						<td>{i+1}</td>
						<td>{getDate(p.created_at)}</td>
						<td>{p.id}</td>
						<td>
							{/*
							   * envio.paquete.productos[0].proveedor_id es el dueño
							   * se compara con user.proveedor_id este es el proveedor logueado
							   * logica: yo soy el dueño? entonces muestrame el boton
							   * todos los productos de el paquete son del mismo proveedor por eso se usa el primer producto
							  */}
							{envio.paquete.productos[0].proveedor_id===user.proveedor_id && envio.status==='Pendiente'?
							<a href='#!' className='btn waves-effect waves-light black text-white' onClick={()=>_enviar(p.id, envio.id)}>Packaging</a>:
							envio.status
							}
						</td>
						<td>{envio.nombre}</td>
						<td>
							<div> {envio.paquete.productos[0].company_name} </div>
							{/**
							   * envio.paquete.productos[0].proveedor_id es el dueño del producto
							   * p.proveedor_id es el vendedor
							   * logica: renderizar el boton solo si yo vendi y no soy el dueño
							   */
							}
							{(p.proveedor_id===user.proveedor_id && envio.paquete.productos[0].proveedor_id!==user.proveedor_id) &&
							<a href='#modal-message' onClick={()=>localStorage.setItem('modal_contactnow_data', JSON.stringify({message:`Pedido_id: ${p.id}`, to_user_uuid:envio.paquete.productos[0].proveedor.message_api_uuid, to_user_name:envio.paquete.productos[0].company_name}))} className='btn waves-effect waves-light modal-trigger' style={{background:'#CD554B', width:'100%'}}><i className="material-icons left">mail</i>Contact Now</a>
							}
						</td>
						<td>{p.company_name}</td>
						<td>
							<div className='card-panel' style={{padding:10, margin:0}}>
								{envio.paquete.productos.map((producto, i)=>
								<div key={i} style={{display:'flex', overflow:'auto', alignItems:'center'}}>
									{producto.cantidad}X {producto.nombre} {producto.codigo}

									{producto.atributos?.find(a=>a.item_no===producto.codigo)?.imgs?.length>0?
										<img alt='producto' src={producto.atributos?.find(a=>a.item_no===producto.codigo)?.imgs[0]} height='50' style={{marginLeft:'auto'}}/>:(
										producto.imagenes?.length>0?
											<img alt='producto' src={producto.imagenes[0].url} height='50' style={{marginLeft:'auto'}}/>:null
										)
									}
								</div>
								)}

								{/**
								   * envio.paquete.productos[0].proveedor_id es el dueño del producto
								   * logica: renderizar el boton entregar solo si yo soy el dueño y el envio es Recolección y no ha sido entregado
								   */
								}
								{(envio.servicio==='Recolección'&&envio.status!=='Entregado'&&envio.paquete.productos[0].proveedor_id===user.proveedor_id)&&
								<Link to={`/entrega/${envio.id}`} className='btn btn-waves btn-light black white-text'>Entrega</Link>
								}
								{envio.servicio!=='Recolección'&&
								<a href={envio.guia} target='_blank' rel='noreferrer' className='black text-white waves-effect waves-light btn'>Print Guide</a>
								}
								
							</div>
						</td>
					</tr> //end tr envio.map
				)
				)}
				</tbody>
			</table>


		</div>
		</>
	);
};

export default Ventas;

