import React, { useState, useEffect } from 'react'
import { axios } from '../../config'
import { Link } from "react-router-dom"
import M from 'materialize-css'

const Catalogo = () => {
	const [view, setSwich] = useState(false)
	const [catalogo, setCatalogo] = useState([])
	const [papelera, setPapelera] = useState([])
	const [search, setSearch] = useState('')
	const [searchArchivados,setSearchArchivados] = useState('')
	const [searchSinCat, setSearchSinCat] = useState('')

	useEffect(()=>{
		axios.get('/api/catalogo')
		.then(r=>{
			setCatalogo(r.data)
			M.Tabs.init(document.querySelectorAll('.tabs'),{})
			
		})
		.catch(r=>alert(r))

		axios.get('/api/getPapelera')
		.then(r=>{
			//console.log("papelera",r.data)
			setPapelera(r.data)
			M.Tooltip.init(document.querySelectorAll('.tooltipped'),{})
		})
		.catch(r=>alert(r))
	},[])

	const _changeView = () =>{
		setSwich(!view)
	}

	const _buildImageToGallery = (images) => {
		return images.length>0?images.map((i)=>({original:i.url})):[{original:'https://bitsofco.de/content/images/2018/12/broken-1.png'}]
	}

	const _restaurar = (id) =>{
		axios.put(`/api/updatePapelera/${id}`)
		.then((r) => {
			M.toast({html: 'Producto Restaurado'})
			setPapelera(papelera.filter(p =>p.id !==id))

			axios.get('/api/catalogo')
			.then(r=>{
				setCatalogo(r.data)
			})
			.catch(r=>alert(r))
		})
		.catch(r=>alert(r))
	}
	/*
	   *
	   * Catalogo obj
	   */
	const _updateCatalogo = (obj,checked) =>{
		const newCatalogo = catalogo.map(c=>c.id===obj.id?{...obj,visible:checked}:c)
		setCatalogo(newCatalogo)
		axios.post('/api/updateVisible',{id:obj.id,visible:checked})
		.then((r) => {
			M.toast({html:'Actualizado!'})
		})
		.catch(r=>alert(r))	
	}


	return (
		<div className="col s12" style={{marginTop:"50px"}}>
			<Link to="/altaproducto" className="btn waves-effect waves-light right black" style={{display:'flex', alignItems:'center'}}><i className="material-icons">flash_on</i>New product</Link>
			<ul id="tabs-swipe-demo" className="tabs ul-tabs">
				<li className="tab col s2"><a className="active" href="#catalogo-tab">Catálogo</a></li>
				<li className="tab col s2"><a href="#papelera-tab">Archivados</a></li>
				<li className="tab col s3"><a href="#productos-otros-tab">Productos sin departamento</a></li>
			</ul>
			<div id="catalogo-tab" className='card-panel'>
				<div className="row m-p-0">
				{!view &&
					<div className="col m12 m-p-0">  
						<i className="small material-icons right" 
						onClick={_changeView}
						style={{cursor:'pointer'}}>tock</i> 
					</div>
				}
				{view &&
					<div className="col m12 m-p-0"> 
						
						<i className="small material-icons right" 
						onClick={_changeView}
						style={{cursor:'pointer'}}>apps</i> 
					</div>
				}
				</div>
				
				<h4>Catálogo</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field white row">
								<div className='col s1 m1' style={{margin:0, padding:0, textAlign:'center'}}> 
									<label className="label-icon" htmlFor="search"><i className="material-icons" style={{color: 'black',}}>search</i></label>
								</div>
								<div className='col s10 m10' style={{margin:0, padding:0,}}> 
									<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" required placeholder='Código, Categoría, Departamento, Nombre'/>
								</div>
								<div className='col s1 m1' style={{margin:0, padding:0, textAlign:'center',}}> <i className="material-icons">close</i> </div>
							</div>
						</form>
					</div>
				</nav>
			{!view?
				<div className="row">
							<table id="table-catalogo">
								<thead>
								<tr>
									<th>foto</th>
									<th>Clave</th>
									<th>Nombre</th>
									<th>Stock</th>
									<th>Visible</th>
									<th>Departamento</th>
									<th>Categoría</th>
									<th>Descripción</th>
									<th>Variante</th>
									<th>Editar</th>
								</tr>
								</thead>

								<tbody>
								{catalogo.filter(c=>c.nombre.toLowerCase().includes(search.toLowerCase()) || c.codigo.includes(search)||c.categoria.toLowerCase().includes(search.toLowerCase()) || c.departamento.toLowerCase().includes(search.toLowerCase())).slice(0,50).map(c=>
								
									<tr key={c.id}>
										<td style={{width:'100px'}}>
										{c.imgs?.length>0?
										<img alt='producto' src={c.imgs[0]} height='50' style={{marginLeft:'auto'}}/>:(
										c.imagenes?.length>0?
											<img alt='producto' src={c.imagenes[0].url} height='50' style={{marginLeft:'auto'}}/>:null
										)
										}
										</td>
										<td> {c.item_no??c.codigo} </td>
										<td> {c.nombre} </td>
										<td> {c.stock} </td>
										<td>
											<div className="switch">
												<label>
													<input type="checkbox" checked={c.visible} onChange={(e)=>_updateCatalogo(c,e.target.checked)}/>
													<span className="lever"></span>
												</label>
											</div> 
										</td>
										<td> {c.departamento} </td>
										<td> {c.categoria} </td>
										<td> {c.descripcion} </td>
										<td> {c.attribute_type}: {c.attribute_value}</td>
										<td>
											<Link to={`/catalogo/producto/${c.id}`} className='black-text'>
												<i className="material-icons">edit</i>
											</Link>
											<Link to={`/catalogo/comentarios/${c.codigo}`} className='black-text'>
												<i className="material-icons">insert_comment</i>
											</Link>
											
										</td>
									</tr>
								)}
								</tbody>
							</table>
				</div>
				:
				<div className="row">
				{catalogo.filter(c=>c.nombre.toLowerCase().includes(search.toLowerCase()) || c.codigo.includes(search)||c.categoria.toLowerCase().includes(search.toLowerCase()) || c.departamento.toLowerCase().includes(search.toLowerCase())).slice(0,50).map((c,i)=>
					<div key={i} className="col m6">
						<div className="card-panel" style={{position:'relative'}}>
							<Link to={`/catalogo/producto/${c.id}`} className='black-text' style={{position:'absolute', right:10, top:10}}>
								<i className="material-icons">edit</i>
							</Link>
						<div className='row'>
							<div className="col m5" id="slider2">
										{c.imgs?.length>0?
										<img alt='producto' src={c.imgs[0]} height='200' style={{marginLeft:'auto'}}/>:(
										c.imagenes?.length>0?
											<img alt='producto' src={c.imagenes[0].url} height='200' style={{marginLeft:'auto'}}/>:null
										)
										}
							</div>

							<div className="col m7">
								<div style={{height:200, overflowY:'auto'}}>
									<div className='black-text'>{c.item_no??c.codigo}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Nombre:</b> {c.nombre}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Descripción:</b> {c.descripcion}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Categorías:</b> {c.categoria}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Departamento:</b> {c.departamento}</div>
								</div>
							</div>
						</div>
						</div>
					</div>
				)}
			
				</div>
			}
			</div>

			<div id="papelera-tab" className='card-panel'>
				<h4>Archivados</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field white">
								<input value={searchArchivados} onChange={e=>setSearchArchivados(e.target.value)} id="searchArch" type="search" required placeholder='Código, Categoría, Departamento, Nombre'/>
								<label className="label-icon" htmlFor="searchArch"><i className="material-icons" style={{color: 'black'}}>search</i></label>
								<i className="material-icons">close</i>
							</div>
						</form>
					</div>
				</nav>
				<div className="row">
				
							<table id="table-catalogo">
								<thead>
								<tr>
									<th></th>
									<th style={{textAlign:"left"}}>Clave</th>
									<th style={{textAlign:"left"}}>Nombre</th>
									<th style={{textAlign:"left"}}>Departamento</th>
									<th style={{textAlign:"left"}}>Categoría</th>
									<th></th>
								
								</tr>
								</thead>

								<tbody>
								{papelera.filter(c=>c.nombre.toLowerCase().includes(searchArchivados.toLowerCase()) || c.codigo.includes(searchArchivados)||c.categoria.toLowerCase().includes(searchArchivados.toLowerCase()) || c.departamento.toLowerCase().includes(searchArchivados.toLowerCase())).slice(0,50).map((c,i)=>
									<tr key={i}>
										<td style={{width:'100px'}}>
										{c.imgs?.length>0?
										<img alt='producto' src={c.imgs[0]} height='50' style={{marginLeft:'auto'}}/>:(
										c.imagenes?.length>0?
											<img alt='producto' src={c.imagenes[0].url} height='50' style={{marginLeft:'auto'}}/>:null
										)
										}
										</td>
										<td> {c.item_no??c.codigo} </td>
										<td> {c.nombre} </td>
										<td> {c.departamento} </td>
										<td> {c.categoria} </td>
										<td>
											<div onClick={()=>_restaurar(c.id)} className='black-text pointer tooltipped' data-position="top" data-tooltip="Restaurar">
												<i className="material-icons">unarchive</i>
											</div>
										</td>
									</tr>
								)}
								</tbody>
							</table>
				</div>
			</div>
			<div id="productos-otros-tab" className='card-panel'>
				<h4>Productos sin departamento</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field white">
								<input value={searchSinCat} onChange={e=>setSearchSinCat(e.target.value)} id="searchCat" type="search" required placeholder='Código, Categoría, Departamento, Nombre'/>
								<label className="label-icon" htmlFor="search"><i className="material-icons" style={{color: 'black'}}>search</i></label>
								<i className="material-icons">close</i>
							</div>
						</form>
					</div>
				</nav>
				<div className="row">
							<table id="table-catalogo">
								<thead>
								<tr>
									<th></th>
									<th>Clave</th>
									<th>Nombre</th>
									<th>Visible</th>
									<th>Departamento</th>
									<th>Categoría</th>
									<th>Descripción</th>
									<th>Precio</th>
									<th></th>
								</tr>
								</thead>

								<tbody>
								{catalogo.filter(c=>(c.nombre.toLowerCase().includes(searchSinCat.toLowerCase()) || c.codigo.includes(searchSinCat)||c.categoria.toLowerCase().includes(searchSinCat.toLowerCase()))&&c.departamento.toLowerCase().includes('otros')).slice(0,50).map((c,i)=>
									<tr key={i}>
										<td style={{width:'100px'}}>
										{c.imgs?.length>0?
										<img alt='producto' src={c.imgs[0]} height='50' style={{marginLeft:'auto'}}/>:(
										c.imagenes?.length>0?
											<img alt='producto' src={c.imagenes[0].url} height='50' style={{marginLeft:'auto'}}/>:null
										)
										}
										</td>
										<td> {c.item_no??c.codigo} </td>
										<td> {c.nombre} </td>
										<td>
											<div className="switch">
												<label>
													<input type="checkbox" checked={c.visible} onChange={(e)=>_updateCatalogo(c,e.target.checked)}/>
													<span className="lever"></span>
												</label>
											</div> 
										</td>
										<td> {c.departamento} </td>
										<td> {c.categoria} </td>
										<td> {c.descripcion} </td>
										<td> 
											<p className="m-p-0" style={{textAlign:'center'}}>{c.precio}</p>
											{c.stock===0&&
											<p className="m-p-0" style={{color:'red', fontWeight:'bold', fontSize:'12px',textAlign:'center'}}>Agotado</p>
											} 
										</td>
										<td>
											<Link to={`/catalogo/producto/${c.id}`} className='black-text'>
												<i className="material-icons">edit</i>
											</Link>
										</td>
									</tr>
								)}
								</tbody>
							</table>
				</div>
			</div>
		</div>
	)
}

export default Catalogo
